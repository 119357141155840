import axios from 'axios'
import { API_BASE_URL } from 'configs/AppConfig'
import { AUTH_TOKEN } from 'redux/constants/Auth'
import { notification, Button } from 'antd';

const service = axios.create({
  baseURL: API_BASE_URL,
  timeout: 60000
})

// Config
const TOKEN_PAYLOAD_KEY = 'Authorization'

// API Request interceptor
service.interceptors.request.use(config => {
	const accessToken = localStorage.getItem(AUTH_TOKEN)
	if (accessToken) {
		config.headers[TOKEN_PAYLOAD_KEY] = `Bearer ${accessToken}`
	}
	return config
}, error => {
	// Do something with request error here
	notification.error({
		message: 'Error'
	})
  Promise.reject(error)
})

// API respone interceptor
service.interceptors.response.use( (response) => {
	return response
}, (error) => {
	let notificationParam = {
		message: ''
	}

	if (error.response === undefined) {
		notificationParam.message = "네트워크 연결 오류"
		notification.error(notificationParam)
	}

	const key = `open${Date.now()}`
	const btn = (<Button type="primary" size="small" onClick={() => window.location.reload(false) }>확인</Button>)

	// Remove token and redirect 
	if (error.response.status === 400 || error.response.status === 401) {
		notificationParam.message = '인증만료'
		notificationParam.description = '확인을 눌러 브라우저를 새로고침해주세요. '
		notificationParam.btn = btn
		notificationParam.key = key
		notificationParam.duration = 0
	}

	if (error.response.status === 400 || error.response.status === 404) {
		notificationParam.message = 'Not Found'
	}

	if (error.response.status === 500) {
		notificationParam.message = '서버 에러'
	}
	
	if (error.response.status === 508) {
		notificationParam.message = '타임아웃 에러'
	}

	notification.error(notificationParam)

	return Promise.reject(error);
});

export default service