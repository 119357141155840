import { all, takeEvery, put, fork, call, take } from 'redux-saga/effects';

import { REQUEST_TRANS, REQUEST_TRANS_MODIFY, REQUEST_RECEIVER_MODIFY, WAIT_CONNECTION_STATUS, CHECK_CONNECTION_STATUS } from '../constants/Transmitter'
import { requestTransLoading, requestTransSuccess, requestTransFailed, updateConnectionStatus, requestTransModifySuccess, requestTransModifyFailed, requestReceiverModifySuccess, requestReceiverModifyFailed, checkReceiverConnectionStatusSuccess, checkReceiverConnectionStatusFailed } from '../actions/Transmitter'
import TowncastService from '../../services/TowncastService'
import { createSignalRConnectionChannel, closeChannel } from './createSignalRChannel';

export function* requestTrans() {
    yield takeEvery(REQUEST_TRANS, function* (action) {
        try {
            yield put(requestTransLoading(true))
            const response = yield call(TowncastService.getTransmitters, action.siteid)
            yield put(requestTransSuccess(response.data))
        } catch (err) {
            yield put(requestTransFailed(err))
        }
    })
}

export function* requestTransModify() {
    yield takeEvery(REQUEST_TRANS_MODIFY, function* (action) {
        try {
            const response = yield call(TowncastService.putTransmitter, action.trans)
            yield put(requestTransModifySuccess(response.data))
        } catch (err) {
            yield put(requestTransModifyFailed(err))
        }
    })
}

export function* requestReceiverModify() {
    yield takeEvery(REQUEST_RECEIVER_MODIFY, function* (action) {
        try {
            const response = yield call(TowncastService.putReceiver, action.receiver)
            yield put(requestReceiverModifySuccess(response.data))
        } catch (err) {
            yield put(requestReceiverModifyFailed(err))
        }
    })
}

export function* waitConnectionUpdate() {
    yield takeEvery(WAIT_CONNECTION_STATUS, function* (action) {
        let channel
        try {
            channel = yield call(createSignalRConnectionChannel, "updateConnectionStatus", action.connection)

            while(true) {
                const status = yield take(channel)

                yield put(updateConnectionStatus(status))
            }
        } catch(e) {
            console.log(e, "error")
        } finally {
            closeChannel(channel)
        }
    })
}

export function* checkReceiverConnectionStatus() {
    yield takeEvery(CHECK_CONNECTION_STATUS, function* (action) {
        try {
            yield call(TowncastService.headReceiver, action.receiver.id)
            yield put(checkReceiverConnectionStatusSuccess())
        } catch (err) {
            yield put(checkReceiverConnectionStatusFailed(err))
        }
    })
}

export default function* rootSaga() {
    yield all([
        fork(requestTrans),
        fork(requestTransModify),
        fork(requestReceiverModify),
        fork(waitConnectionUpdate),
        fork(checkReceiverConnectionStatus)
    ])
}