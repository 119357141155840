import { 
    REQUEST_BROADCASTS_GROUP_LOADING, REQUEST_BROADCASTS_GROUP_SUCCESS,REQUEST_BROADCASTS_GROUP_FAILED,
    ADD_BROADCASTS_GROUP_FAILED, ADD_BROADCASTS_GROUP_SUCCESS,
    UPDATE_BROADCASTS_GROUP_FAILED, UPDATE_BROADCASTS_GROUP_SUCCESS,
    DELETE_BROADCASTS_GROUP_FAILED, DELETE_BROADCASTS_GROUP_SUCCESS
} from '../constants/BroadcastGroup'

const initState = {
    data: [],
    loading: false,
    err: null
}

const broadcastGroups = (state = initState, action) => {
    switch (action.type) {
        case REQUEST_BROADCASTS_GROUP_LOADING:
            return {
                ...state,
                loading: true
            }
        case REQUEST_BROADCASTS_GROUP_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.data,
                err: null
            }
        case REQUEST_BROADCASTS_GROUP_FAILED:
            return {
                ...state,
                loading: false,
                err: action.error
            }
        case ADD_BROADCASTS_GROUP_SUCCESS:
            const recentGroup = [action.data]
            return {
                ...state,
                data: recentGroup.concat(state.data)
            }
        case ADD_BROADCASTS_GROUP_FAILED: 
            return {
                ...state,
                err: action.error
            }
        case UPDATE_BROADCASTS_GROUP_SUCCESS:
            return {
                ...state,
                data: state.data.map( (b) => b.id === action.data.id ? action.data : b)
            }
        case UPDATE_BROADCASTS_GROUP_FAILED: 
            return {
                ...state,
                err: action.error
            }
        case DELETE_BROADCASTS_GROUP_SUCCESS: 
            return {
                ...state,
                data: state.data.filter( (b) => b.id !== action.id)
            }
        case DELETE_BROADCASTS_GROUP_FAILED:
            return {
                ...state,
                err: action.error
            }
        default:
            return state;
    }
}

export default broadcastGroups