import { 
    REQUEST_BROADCASTS_LOG_LOADING, 
    REQUEST_BROADCASTS_LOG_SUCCESS, 
    REQUEST_BROADCASTS_LOG_FAILED, 
    ADD_RECENT_BROADCAST_LOG,
    REQUEST_BROADCASTS_LOADING,
    REQUEST_BROADCASTS_SUCCESS,
    REQUEST_BROADCASTS_FAILED,
    GET_BROADCASTS_JOB_STATUS_SUCCESS,
    GET_BROADCASTS_JOB_STATUS_FAILED
} from '../constants/Broadcast'

const initState = {
    data: [],
    loading: false,
    err: null,
    jobLoading: false,
    jobErr: null,
    job: null
}

const broadcasts = (state = initState, action) => {
    switch (action.type) {
        case REQUEST_BROADCASTS_LOADING:
            return {
                ...state,
                loading: action.isLoading,
                err: null
            }
        case REQUEST_BROADCASTS_SUCCESS:    // IoT Hub Job --> Update BroadcastDTO object
            const binfo = [action.data]
            return {
                ...state,
                data: (state.data.findIndex(x => x.id === action.data.id) === -1 ? binfo.concat(state.data) : state.data.map( b => b.id === action.data.id ? action.data : b)),
                loading: false,
                err: null
            }
        case REQUEST_BROADCASTS_FAILED:
            return {
                ...state,
                loading: false,
                err: action.error
            }
        case GET_BROADCASTS_JOB_STATUS_SUCCESS:
            return {
                ...state,
                jobsLoading: false,
                jobsErr: null,
                job: action.data
            }
        case GET_BROADCASTS_JOB_STATUS_FAILED: 
            return {
                ...state,
                jobsLoading: false,
                jobsErr: action.err,
                job: null
            }
        case REQUEST_BROADCASTS_LOG_LOADING:
            return {
                ...state,
                loading: action.isLoading,
                err: null
            }
        case REQUEST_BROADCASTS_LOG_SUCCESS: 
            return {
                ...state,
                data: action.data,
                loading: false,
                err: null
            }
        case REQUEST_BROADCASTS_LOG_FAILED: 
            return {
                ...state,
                data: [],
                loading: false,
                err: action.error
            }
        case ADD_RECENT_BROADCAST_LOG:
            const broadcastArr = [action.broadcast]
            return {
                ...state,
                data: (state.data.findIndex(x => x.id === action.broadcast.id) === -1 ? broadcastArr.concat(state.data) : state.data.map( b => b.id === action.broadcast.id ? action.broadcast : b))
            }
        default:
            return state;
    }
}

export default broadcasts