import { 
  DashboardOutlined,
  AlertOutlined,
  InfoCircleOutlined,
  MonitorOutlined,
  NotificationOutlined,
  ProfileOutlined,
  GlobalOutlined,
  EnvironmentOutlined,
  PushpinOutlined
} from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig'

const monitorNavTree = [{
  key: 'monitor',
  path: `${APP_PREFIX_PATH}/monitor`,
  title: 'sidenav.monitor',
  icon: MonitorOutlined,
  breadcrumb: false,
  submenu: [{
    key: 'dashboards',
    path: `${APP_PREFIX_PATH}/dashboards`,
    title: 'sidenav.dashboard',
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: []
  },
  {
    key: 'monitor-map',
    path: `${APP_PREFIX_PATH}/monitor`,
    title: 'sidenav.monitor.map',
    icon: GlobalOutlined,
    breadcrumb: false,
    submenu: []
  },
  {
    key: 'alerts',
    path: `${APP_PREFIX_PATH}/alerts`,
    title: 'sidenav.alert',
    icon: AlertOutlined,
    breadcrumb: false,
    submenu: []
  }]
}]

const deviceNavTree = [{
  key: 'devices',
  path: `${APP_PREFIX_PATH}/devices`,
  title: 'sidenav.device',
  icon: InfoCircleOutlined,
  breadcrumb: false,
  submenu: [{
    key: 'receiver-list',
    path: `${APP_PREFIX_PATH}/devices`,
    title: 'sidenav.device.list',
    icon: PushpinOutlined,
    breadcrumb: false,
    submenu: []
  },
  {
    key: 'transmitter-list',
    path: `${APP_PREFIX_PATH}/transmitters`,
    title: 'sidenav.device.translist',
    icon: EnvironmentOutlined,
    breadcrumb: false,
    submenu: []
  }]
}]

const broadcastTree = [{
  key: 'broadcast',
  path: `${APP_PREFIX_PATH}/broadcasts`,
  title: 'sidenav.broadcast',
  icon: NotificationOutlined,
  breadcrumb: false,
  submenu: [
    {
      key: 'broadcast-delivery',
      path: `${APP_PREFIX_PATH}/broadcasts/delivery`,
      title: 'sidenav.broadcast.delivery',
      icon: NotificationOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'broadcast-logs',
      path: `${APP_PREFIX_PATH}/broadcasts/logs`,
      title: 'sidenav.broadcast.logs',
      icon: ProfileOutlined,
      breadcrumb: false,
      submenu: []
    }
  ]
}]

const navigationConfig = [
  ...monitorNavTree,
  ...broadcastTree,
  ...deviceNavTree
]

export default navigationConfig;
