import { all, takeEvery, put, fork, call, take } from 'redux-saga/effects';

import { REQUEST_BROADCASTS, GET_BROADCASTS_JOB_STAUTS, REQUEST_BROADCASTS_LOG, WAIT_RECENT_BROADCAST_LOG } from '../constants/Broadcast'
import { 
        requestBroadcastsLoading, 
        requestBroadcastsSuccess, 
        requestBroadcastsFailed, 
        getBroadcastsJobStatusSuccess,
        getBroadcastsJobStatusFailed,
        requestBroadcastsLogLoading, 
        requestBroadcastsLogSuccess, 
        requestBroadcastsLogFailed, 
        addRecentBroadcastLog 
    } from '../actions/Broadcast'
import TowncastService from '../../services/TowncastService'
import { createSignalRBroadcastLogChannel, closeChannel } from './createSignalRChannel';

export function* requestBroadcasts() {
    yield takeEvery(REQUEST_BROADCASTS, function* (action) {
        try {
            yield put(requestBroadcastsLoading(true))
            const response = yield call(TowncastService.postBroadcast, action.siteid, action.info)
            yield put(requestBroadcastsSuccess(response.data))
        } catch (err) {
            yield put(requestBroadcastsFailed(err))
        }
    })
}

export function* getBroadcastsJobStatus() {
    yield takeEvery(GET_BROADCASTS_JOB_STAUTS, function* (action) {
        try {
            const response = yield call(TowncastService.getIoTHubJob, action.id, action.siteid)
            yield put(getBroadcastsJobStatusSuccess(response.data))
        } catch (err) {
            yield put(getBroadcastsJobStatusFailed(err))
        }
    })
}

export function* requestBroadcastsLog() {
    yield takeEvery(REQUEST_BROADCASTS_LOG, function* (action) {
        try {
            yield put(requestBroadcastsLogLoading(true))
            const response = yield call(TowncastService.getBroadcasts, action.siteid, action.start, action.end)
            yield put(requestBroadcastsLogSuccess(response.data))
        } catch (err) {
            yield put(requestBroadcastsLogFailed(err))
        }
    })
}

export function* waitRecentBroadcastLog() {
    yield takeEvery(WAIT_RECENT_BROADCAST_LOG, function* (action) {
        let channel
        try {
            channel = yield call(createSignalRBroadcastLogChannel, "addBroadcastLog", action.connection)

            while(true) {
                const broadcastlog = yield take(channel)
                yield put(addRecentBroadcastLog(broadcastlog))
            }
        } catch(e) {
            console.log(e, "error")
        } finally {
            closeChannel(channel)
        }
    })
}

export default function* rootSaga() {
    yield all([
        fork(requestBroadcasts),
        fork(getBroadcastsJobStatus),
        fork(requestBroadcastsLog),
        fork(waitRecentBroadcastLog)
    ])
}