import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE, DIR_LTR } from 'constants/ThemeConstant';
import { API_ENDPOINT_URL, SIGNALR_ENDPOINT_URL } from './EnvironmentConfig'
import { CURRENT_THEME } from 'redux/constants/Theme'

export const APP_NAME = '마을방송';
export const API_BASE_URL = API_ENDPOINT_URL
export const SIGNALR_BASE_URL = SIGNALR_ENDPOINT_URL
export const APP_PREFIX_PATH = '/app';

const getCurrentTheme = () => {
	const theme = localStorage.getItem(CURRENT_THEME)
	if (theme && (theme === 'light' || theme === 'dark')) return theme
	else return 'light'
}

export const THEME_CONFIG = {
	navCollapsed: false,
	sideNavTheme: SIDE_NAV_LIGHT,
	locale: 'ko',
	navType: NAV_TYPE_SIDE,
	topNavColor: '#3e82f7',
	headerNavColor: '',
	mobileNav: false,
	currentTheme: getCurrentTheme(),
	direction: DIR_LTR
};
