import React, { useEffect } from "react";
import { useSelector } from "react-redux"
import { notification } from 'antd'
import { useHistory, useLocation } from "react-router-dom"
import { APP_PREFIX_PATH } from 'configs/AppConfig'
import { AlertOutlined, FireOutlined, ToolOutlined, SettingOutlined, NotificationOutlined, MenuOutlined } from '@ant-design/icons';

const type2Title = ({eventType}) => {
    switch(eventType) {
        case 'Fire':
            return `화재경보 발생`
        case 'Emergency':
            return `응급경보 발생`
        case 'Maintenance':
            return "점검중"
        case 'AirQuaility':
            return "공기질 정보"
        case 'Disaster':
                return "재난경보"
        default: 
            return `경보 발생`
    }
}

const info2Desc = ({receiverName, receiverAddress }) => {
    if (receiverName === "") return `${receiverAddress}`
    else return `${receiverName} - ${receiverAddress}`
}

const typeIcon = (eventType) => {
    switch(eventType) {
        case 'Fire':
            return <FireOutlined style={{ color: '#ff0000' }} />
        case 'Emergency':
            return <AlertOutlined style={{ color: '#ff8000' }} />
        case 'Maintenance':
            return <ToolOutlined style={{ color: 'gray' }}/>
        case 'AirQuaility':
            return <MenuOutlined style={{ color: '#DC7633' }}/>
        case 'Disaster':
            return <NotificationOutlined style={{ color: '#34495E' }}/>
        default: 
            return <SettingOutlined />
    }
}

const AlertNotification = () => {
    let history = useHistory();
    const recentAlert = useSelector((state) => state.alerts.recentAlert)

    const location = useLocation();

    useEffect(() => {
        if (recentAlert) {
            const notificationParam = {
                key: recentAlert.id,
                message: type2Title(recentAlert),
                description: info2Desc(recentAlert),
                onClick: () => {

                    if (recentAlert.eventType === "AirQuaility" || recentAlert.eventType === "Disaster") return;

                    // TODO: 꼼수 ... 
                    const pathname = location.pathname;
                    if (pathname.startsWith(`${APP_PREFIX_PATH}/monitor`)) {
                        history.push(`${APP_PREFIX_PATH}/monitor`)
                        setTimeout(() => {
                            history.push(`${APP_PREFIX_PATH}/monitor/${recentAlert.receiverId}`)
                        }, 1000)
                    }
                    else {
                        history.push(`${APP_PREFIX_PATH}/monitor/${recentAlert.receiverId}`)
                    }
                    notification.close(recentAlert.id)
                },
                duration: 0,
                icon: typeIcon(recentAlert.eventType),
            }
        
            notification.error(notificationParam)

            // Show overlay when user is in monitor page
            if (location.pathname.startsWith(`${APP_PREFIX_PATH}/monitor`) && (recentAlert.eventType !== "AirQuaility" && recentAlert.eventType !== "Disaster")) 
            {
                if (!location.pathname.endsWith(recentAlert.receiverId))
                {
                    history.push(`${APP_PREFIX_PATH}/monitor`)
                    setTimeout(() => {
                        history.push(`${APP_PREFIX_PATH}/monitor/${recentAlert.receiverId}`)
                    }, 1000)
                }
            }
        }
        // eslint-disable-next-line 
    }, [recentAlert])

    return null;
}

export default AlertNotification
