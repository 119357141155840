import { combineReducers } from 'redux'
import Theme from './Theme';
import Transmitter from './Transmitter'
import Site from './Site'
import Alerts from './Alerts'
import Dashboard from './Dashboard'
import SignalR from './SignalR'
import Broadcast from './Broadcast'
import BroadcastGroup from './BroadcastGroup'

const reducers = combineReducers({
    theme: Theme,
    transmitters: Transmitter,
    site: Site,
    alerts: Alerts,
    dashboard: Dashboard,
    signalr: SignalR,
    broadcasts: Broadcast,
    broadcastGroups: BroadcastGroup
});

export default reducers;