import { all } from 'redux-saga/effects'
import Transmitter from './Transmitter'
import Site from './Site'
import Alerts from './Alerts'
import Dashboard from './Dashboard'
import Broadcast from './Broadcast'
import BroadcastGroup from './BroadcastGroup'

export default function* rootSaga(getState) {
  yield all([
    Transmitter(),
    Site(),
    Alerts(),
    Dashboard(),
    Broadcast(),
    BroadcastGroup()
  ]);
}
