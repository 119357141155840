export const REQUEST_ALERTS = 'REQUEST_ALERTS'
export const REQUEST_ALERTS_LOADING = 'REQUEST_ALERTS_LOADING'
export const REQUEST_ALERTS_SUCCESS = 'REQUEST_ALERTS_SUCCESS'
export const REQUEST_ALERTS_FAILED = 'REQUEST_ALERTS_FAILED'

export const REQUEST_ALERTS_MODIFY = 'REQUEST_ALERTS_MODIFY'
export const REQUEST_ALERTS_MODIFY_SUCCESS = 'REQUEST_ALERTS_MODIFY_SUCCESS'
export const REQUEST_ALERTS_MODIFY_FAILED = 'REQUEST_ALERTS_MODIFY_FAILED'

export const REQUEST_ALL_ALERTS_DONE = 'REQUEST_ALL_ALERTS_DONE'
export const REQUEST_ALL_ALERTS_DONE_SUCCESS = 'REQUEST_ALL_ALERTS_DONE_SUCCESS'
export const REQUEST_ALL_ALERTS_DONE_FAILED = 'REQUEST_ALL_ALERTS_DONE_FAILED'

export const WAIT_RECENT_ALERT = 'WAIT_RECENT_ALERT'
export const ADD_RECENT_ALERT = 'ADD_RECENT_ALERT'