export const REQUEST_BROADCASTS = 'REQUEST_BROADCASTS'
export const REQUEST_BROADCASTS_LOADING = 'REQUEST_BROADCASTS_LOADING'
export const REQUEST_BROADCASTS_SUCCESS = 'REQUEST_BROADCASTS_SUCCESS'
export const REQUEST_BROADCASTS_FAILED = 'REQUEST_BROADCASTS_FAILED'

export const GET_BROADCASTS_JOB_STAUTS = 'GET_BROADCASTS_JOB_STAUTS'
export const GET_BROADCASTS_JOB_STATUS_SUCCESS = 'GET_BROADCASTS_JOB_STATUS_SUCCESS'
export const GET_BROADCASTS_JOB_STATUS_FAILED = 'GET_BROADCASTS_JOB_STATUS_FAILED'

export const REQUEST_BROADCASTS_LOG = 'REQUEST_BROADCASTS_LOG'
export const REQUEST_BROADCASTS_LOG_LOADING = 'REQUEST_BROADCASTS_LOG_LOADING'
export const REQUEST_BROADCASTS_LOG_SUCCESS = 'REQUEST_BROADCASTS_LOG_SUCCESS'
export const REQUEST_BROADCASTS_LOG_FAILED = 'REQUEST_BROADCASTS_LOG_FAILED'

export const WAIT_RECENT_BROADCAST_LOG = 'WAIT_RECENT_BROADCAST_LOG'
export const ADD_RECENT_BROADCAST_LOG = 'ADD_RECENT_BROADCAST_LOG'