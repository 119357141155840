export const REQUEST_TRANS = 'REQUEST_TRANSMITTERS';
export const REQUEST_TRANS_LOADING = 'REQUEST_TRANS_LOADING';
export const REQUEST_TRANS_SUCCESS = 'REQUEST_TRANSMITTERS_SUCCESS'
export const REQUEST_TRANS_FAILED = 'REQUEST_TRANS_FAILED'

export const REQUEST_TRANS_MODIFY = 'REQUEST_TRANS_MODIFY'
export const REQUEST_TRANS_MODIFY_SUCCESS = 'REQUEST_TRANS_MODIFY_SUCCESS'
export const REQUEST_TRANS_MODIFY_FAILED = 'REQUEST_TRANS_MODIFY_FAILED'

export const REQUEST_RECEIVER_MODIFY = 'REQUEST_RECEIVER_MODIFY'
export const REQUEST_RECEIVER_MODIFY_SUCCESS = 'REQUEST_RECEIVER_MODIFY_SUCCESS'
export const REQUEST_RECEIVER_MODIFY_FAILED = 'REQUEST_RECEIVER_MODIFY_FAILED'

export const WAIT_CONNECTION_STATUS  = 'WAIT_CONNECTION_STATUS'
export const UPDATE_CONNECTION_STATUS = 'UPDATE_CONNECTION_STATUS'

export const CHECK_CONNECTION_STATUS = 'CHECK_CONNECTION_STATUS'
export const CHECK_CONNECTION_STATUS_SUCCESS = 'CHECK_CONNECTION_STATUS_SUCCESS'
export const CHECK_CONNECTION_STATUS_FAILED = 'CHECK_CONNECTION_STATUS_FAILED'