import React from 'react'
import { AudioFilled, SyncOutlined } from '@ant-design/icons';
import { APP_NAME } from 'configs/AppConfig'
import { useSelector } from "react-redux";

export default function Footer() {

	const loading = useSelector((state) => state.transmitters.loading)
	const site = useSelector((state) => state.site.data)
	const signalrStatus = useSelector((state) => state.signalr.status)

	const statusToText = (status) => {
		if (status === 'connected') return '연결됨'
		else if (status === 'disconnected') return '연결끊김'
		else if (status === 'reconnecting') return '다시 연결중'
		else return ''
	}

	return (
		<footer className="footer">
			<span className={signalrStatus === 'disconnected' || signalrStatus === 'reconnecting' ? 'text-danger blink' : ''}><AudioFilled /> {site ? site.name : ''} 마을방송 시스템 - {statusToText(signalrStatus)}</span>
			{loading ? <span><SyncOutlined spin /> 마을 데이터 가져오는 중 ...</span> : <></>}
			<span>Copyright  &copy;  {`${new Date().getFullYear()}`} <span className="font-weight-semibold">{`${APP_NAME}`}</span> All rights reserved.</span>
		</footer>
	)
}