import { REQUEST_SITES, REQUEST_SITES_SUCCESS, REQUEST_SITES_FAILED, REQUEST_SITES_LOADING } from '../constants/Site'

export const requestSites = (siteid) => {
    return {
        type: REQUEST_SITES,
        siteid
    }
}

export const requestSitesLoading = (isLoading) => {
    return {
        type: REQUEST_SITES_LOADING,
        isLoading
    }
}

export const requestSitesSuccess = (data) => {
    return {
        type: REQUEST_SITES_SUCCESS,
        data
    }
}

export const requestSitesFailed = (error) => {
    return {
        type: REQUEST_SITES_FAILED,
        error
    }
}