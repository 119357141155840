import {
    REQUEST_BROADCASTS, 
    REQUEST_BROADCASTS_LOADING,
    REQUEST_BROADCASTS_SUCCESS, 
    REQUEST_BROADCASTS_FAILED, 
    GET_BROADCASTS_JOB_STAUTS,
    GET_BROADCASTS_JOB_STATUS_SUCCESS,
    GET_BROADCASTS_JOB_STATUS_FAILED,
    REQUEST_BROADCASTS_LOG, 
    REQUEST_BROADCASTS_LOG_LOADING, 
    REQUEST_BROADCASTS_LOG_SUCCESS, 
    REQUEST_BROADCASTS_LOG_FAILED, 
    WAIT_RECENT_BROADCAST_LOG,
    ADD_RECENT_BROADCAST_LOG
    } from '../constants/Broadcast'

export const requestBroadcasts = (siteid, info) => {
    return {
        type: REQUEST_BROADCASTS,
        siteid,
        info
    }
}

export const requestBroadcastsLoading = (isLoading) => {
    return {
        type: REQUEST_BROADCASTS_LOADING,
        isLoading
    }
}

export const requestBroadcastsSuccess = (data) => {
    return {
        type: REQUEST_BROADCASTS_SUCCESS,
        data
    }
}

export const requestBroadcastsFailed = (error) => {
    return {
        type: REQUEST_BROADCASTS_FAILED,
        error
    }
}

export const getBroadcastsJobStatus = (id, siteid) => {
    return {
        type: GET_BROADCASTS_JOB_STAUTS,
        id,
        siteid
    }
}

export const getBroadcastsJobStatusSuccess = (data) => {
    return {
        type: GET_BROADCASTS_JOB_STATUS_SUCCESS,
        data
    }
}

export const getBroadcastsJobStatusFailed = (error) => {
    return {
        type: GET_BROADCASTS_JOB_STATUS_FAILED,
        error
    }
}

export const requestBroadcastsLog = (siteid, start, end) => {
    return {
        type: REQUEST_BROADCASTS_LOG,
        siteid,
        start,
        end
    }
}

export const requestBroadcastsLogLoading = (isLoading) => {
    return {
        type: REQUEST_BROADCASTS_LOG_LOADING,
        isLoading
    }
}

export const requestBroadcastsLogSuccess = (data) => {
    return {
        type: REQUEST_BROADCASTS_LOG_SUCCESS,
        data
    }
}

export const requestBroadcastsLogFailed = (error) => {
    return {
        type: REQUEST_BROADCASTS_LOG_FAILED,
        error
    }
}

export const waitRecentBroadcastLog = (connection) => {
    return {
        type: WAIT_RECENT_BROADCAST_LOG,
        connection
    }
    
}

export const addRecentBroadcastLog = (broadcast) => {
    return {
        type: ADD_RECENT_BROADCAST_LOG,
        broadcast
    }
}