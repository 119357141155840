import { all, takeEvery, put, fork, call } from 'redux-saga/effects';

import { REQUEST_BROADCASTS_GROUP, UPDATE_BROADCASTS_GROUP, ADD_BROADCASTS_GROUP, DELETE_BROADCASTS_GROUP } from '../constants/BroadcastGroup'
import { 
    requestBroadcastsGroupLoading, requestBroadcastsGroupFailed, requestBroadcastsGroupSuccess,
    updateBroadcastsGroupSuccess, updateBroadcastsGroupFailed,
    addBroadcastsGroupSuccess, addBroadcastsGroupFailed,
    deleteBroadcastsGroupSuccess, deleteBroadcastsGroupFailed
} from '../actions/BroadcastGroup'
import TowncastService from '../../services/TowncastService'

export function* requestBroadcastsGroup() {
    yield takeEvery(REQUEST_BROADCASTS_GROUP, function* (action) {
        try {
            yield put(requestBroadcastsGroupLoading(true))
            const response = yield call(TowncastService.getBroadcastGroup, action.siteid)
            yield put(requestBroadcastsGroupSuccess(response.data))
        } catch (err) {
            yield put(requestBroadcastsGroupFailed(err))
        }
    })
}

export function* putBroadcastsGroup() {
    yield takeEvery(UPDATE_BROADCASTS_GROUP, function* (action) {
        console.log("putBroadcastsGroup", action.siteid, action.id, action.info)
        try {
            const response = yield call(TowncastService.putBroadcastGroup, action.siteid, action.id, action.info)
            yield put(updateBroadcastsGroupSuccess(response.data))
        } catch (err) {
            yield put(updateBroadcastsGroupFailed(err))
        }
    })
}

export function* postBroadcastsGroup() {
    yield takeEvery(ADD_BROADCASTS_GROUP, function* (action) {
        try {
            const response = yield call(TowncastService.postBroadcastGroup, action.siteid, action.name, action.trans)
            yield put(addBroadcastsGroupSuccess(response.data))
        } catch (err) {
            yield put(addBroadcastsGroupFailed(err))
        }
    })
}

export function* deleteBroadcastsGroup() {
    yield takeEvery(DELETE_BROADCASTS_GROUP, function* (action) {
        try {
            const response = yield call(TowncastService.deleteBroadcastGroup, action.siteid, action.id)
            yield put(deleteBroadcastsGroupSuccess(response.data))
        } catch (err) {
            yield put(deleteBroadcastsGroupFailed(err))
        }
    })
}


export default function* rootSaga() {
    yield all([
        fork(requestBroadcastsGroup),
        fork(putBroadcastsGroup),
        fork(postBroadcastsGroup),
        fork(deleteBroadcastsGroup)
    ])
}