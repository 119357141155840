import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux"
import { 
	SearchOutlined
} from '@ant-design/icons';
import { AutoComplete, Input } from 'antd';
import { useIntl } from 'react-intl'
import { useHistory } from "react-router-dom"

import { requestTrans } from 'redux/actions/Transmitter'
import { requestAlerts } from 'redux/actions/Alerts'
import { APP_PREFIX_PATH } from 'configs/AppConfig'

const searchResult = (transmitters, keyword, history) => {
	
	const searchText = (item, key) => {
		if (item === null) return false

		let re = new RegExp(key)
		if (item.name !== null && item.name.search(re) !== -1 ) return true
		else if (item.address !== null && item.address.search(re) !== -1 ) return true
		
		return false
	};
	
	const receivers = transmitters.flatMap((elem) => elem.receivers)
	const nameSearch = receivers.filter(value => searchText(value, keyword));

	

	const result = nameSearch.slice(0, 5).map((item) => {
		
		const goToMap = () => {
			const linkto = `${APP_PREFIX_PATH}/monitor/${item.id}`;

			const pathname = window.location.pathname;
			if (pathname.startsWith(`${APP_PREFIX_PATH}/monitor`)) {
				history.push(`${APP_PREFIX_PATH}/monitor`)
				setTimeout(() => {
					history.push(linkto)
				}, 1000)
			}
			else {
				history.push(linkto)
			}
		}

		return {
			value: item.id,
			label: (
				<div className="search-list-item" onClick={goToMap}>
					<div className="icon">
						<SearchOutlined />
					</div>
					<div>
						<div className="font-weight-semibold">{item.name}</div>
						<div className="font-size-sm text-muted">{item.address} </div>
					</div>
				</div>
			),
		};
	});

	return result;
}


const SearchInput = props => {
	const { active, close, isMobile, mode } = props
	const [value, setValue] = useState('');
	const [options, setOptions] = useState([])
	const inputRef = useRef(null);
	let history = useHistory();

	const dispatch = useDispatch();
	const site = useSelector((state) => state.site.data)
	const trans = useSelector((state) => state.transmitters.data)

	const intl = useIntl()
	const searchPlaceholder = intl.messages['headnav.navsearch.searchinput.search']

	useEffect(() => {
        if (site) dispatch(requestTrans(site.id))
        if (site) dispatch(requestAlerts(site.id))
    }, [dispatch, site])


	const onSelect = () => {
		setValue('')
		setOptions([])
		if(close) {
			close()
		}
  	};

	const onSearch = searchText => {
		// 입력이 변경될 때마다... 
		// 여기서 검색해서 결과를 setOptions 에 넣으면 표시됨. 
		setValue(searchText)
		setOptions(!searchText ? [] : searchResult(trans, searchText, history))
	};
	
	const autofocus = () => {
		inputRef.current.focus();
	}

	if(active) {
		autofocus()
	}

	return (
		<AutoComplete
			ref={inputRef} 
			className={`nav-search-input ${isMobile? 'is-mobile' : ''} ${mode === 'light' ? 'light' : ''}`}
			dropdownClassName="nav-search-dropdown"
			options={options}
			onSelect={onSelect}
			onSearch={onSearch}
			value={value}
			// filterOption={(inputValue, option) => 
			// 	option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
			// }
		>
			<Input placeholder={searchPlaceholder}  prefix={<SearchOutlined className="mr-0" />} />
		</AutoComplete>
	)
}

export default SearchInput
