import React from "react";
import { Menu, Dropdown, Avatar } from "antd";
import { 
  SettingOutlined, 
  LogoutOutlined 
} from '@ant-design/icons';
import IntlMessage from "../util-components/IntlMessage";
import { useMsal } from "@azure/msal-react";
import { forgotPasswordRequest } from "configs/AADB2CConfig";


export const NavProfile = () => {
  const { instance } = useMsal();

  const account = instance.getActiveAccount();

  const authRequest = {
    ...forgotPasswordRequest
  };


  const handleLogout = (logoutType) => {
    if (logoutType === "popup") {
        instance.logoutPopup();
    } else if (logoutType === "redirect") {
        instance.logoutRedirect();
    }
  }

  const handleResetPwd = () => {
    instance.loginRedirect(authRequest);
  }

  const setLocale = (isLocaleOn, localeKey) =>
    isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

  const profileImg = "/img/avatars/default.png";
  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <Avatar size={45} src={profileImg} />
          <div className="pl-3">
            <h4 className="mb-0">{account.name}</h4>
            <span className="text-muted">{account.idTokenClaims.extension_SiteName} {account.idTokenClaims.extension_UserType !== 'User' ? '관리자' : '사용자'}</span>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          <Menu.Item key="0" onClick={e => handleResetPwd()}>
            <span>
              <SettingOutlined className="mr-3"/>
              <span className="font-weight-normal">{setLocale(true, 'headnav.navprofile.menu.resetpassword')}</span>
            </span>
          </Menu.Item>
          <Menu.Item key="1" onClick={e => handleLogout("redirect")}>
            <span>
              <LogoutOutlined className="mr-3"/>
              <span className="font-weight-normal">{setLocale(true, 'headnav.navprofile.menu.signout')}</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item>
          <Avatar src={profileImg} />
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
}

export default NavProfile
