import fetch from 'auth/FetchInterceptor'

const TowncastService = {}

TowncastService.getTransmitters = (siteid) => {
  return fetch({
    url: '/api/transmitters',
    method: 'get',
    params: {
      siteid: siteid
    }
  })
}

TowncastService.getSite = (siteid) => {
  return fetch({
    url: `/api/sites/${siteid}`,
    method: 'get'
  })
}

TowncastService.getAlerts = (siteid, start, end) => {
  return fetch({
    url: '/api/alerts',
    method: 'get',
    params: {
      siteid,
      start,
      end
    }
  })
}

TowncastService.getAllAlertsDone = (siteid, start, end) => {
  return fetch({
    url: '/api/alertsdone',
    method: 'put',
    params: {
      siteid,
      start,
      end
    }
  })
}

TowncastService.downloadAlerts = (siteid, start, end) => {
  return fetch({
    url: '/export/alert',
    method: 'get',
    responseType: 'blob',
    params: {
      siteid,
      start,
      end
    }
  })
}

TowncastService.putAlert = (alert) => {
  return fetch({
    url:`/api/alerts/${alert.id}`,
    method: 'put',
    data: alert
  })
}

TowncastService.putTransmitter = (trans) => {
  return fetch({
    url:`/api/transmitters/${trans.id}`,
    method: 'put',
    data: trans
  })
}

TowncastService.putReceiver = (receiver) => {
  return fetch({
    url:`/api/receivers/${receiver.id}`,
    method: 'put',
    data: receiver
  })
}

TowncastService.getDashboard = (siteid) => {
  return fetch({
    url: `/api/dashboard/${siteid}`,
    method: 'get'
  })
}

TowncastService.getBroadcasts = (siteid, start, end) => {
  return fetch({
    url: '/api/broadcasts',
    method: 'get',
    params: {
      siteid,
      start,
      end
    }
  })
}

TowncastService.downloadBroadcasts = (siteid, start, end) => {
  return fetch({
    url: '/export/broadcast',
    method: 'get',
    responseType: 'blob',
    params: {
      siteid,
      start,
      end
    }
  })
}

TowncastService.postBroadcast = (siteid, info) => {
  return fetch({
    url: `/api/broadcasts`,
    method: 'post',
    params: {
      siteid
    },
    data: info
  })
}

TowncastService.getIoTHubJob = (id, siteid) => {
  return fetch({
    url: `/api/iotjobs/${id}`,
    method: 'get',
    params: {
      siteid
    }
  })
}

TowncastService.headReceiver = (id) => {
  return fetch({
    url: `/api/receivers/${id}`,
    method: 'head'
  })
}

TowncastService.deleteFile = (id) => {
  return fetch({
    url: `/api/file/${id}`,
    method: 'delete'
  })
}

TowncastService.downloadFile = (id) => {
  return fetch({
    url: `/api/file/${id}`,
    method: 'get',
    responseType: 'blob'
  })
}

TowncastService.getBroadcastGroup = (siteid) => {
  return fetch({
    url: '/api/broadcastgroups',
    method: 'get',
    params: {
      siteid
    }
  })
}

TowncastService.putBroadcastGroup = (siteid, id, info) => {
  return fetch({
    url: `/api/broadcastgroups/${id}`,
    method: 'put',
    params: {
      siteid,
    },
    data: info
  })
}

TowncastService.postBroadcastGroup = (siteid, name, trans) => {
  return fetch({
    url: '/api/broadcastgroups',
    method: 'post',
    params: {
      siteid,
      name
    },
    data: trans
  })
}

TowncastService.deleteBroadcastGroup = (siteid, id) => {
  return fetch({
    url: `/api/broadcastgroups/${id}`,
    method: 'delete',
    params: {
      siteid
    }
  })
}

export default TowncastService