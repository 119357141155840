import { REQUEST_TRANS, 
    REQUEST_TRANS_SUCCESS, 
    REQUEST_TRANS_FAILED, 
    REQUEST_TRANS_LOADING, 
    REQUEST_TRANS_MODIFY,
    REQUEST_TRANS_MODIFY_SUCCESS,
    REQUEST_TRANS_MODIFY_FAILED,
    REQUEST_RECEIVER_MODIFY, 
    REQUEST_RECEIVER_MODIFY_SUCCESS, 
    REQUEST_RECEIVER_MODIFY_FAILED,
    UPDATE_CONNECTION_STATUS,
    WAIT_CONNECTION_STATUS,
    CHECK_CONNECTION_STATUS,
    CHECK_CONNECTION_STATUS_SUCCESS,
    CHECK_CONNECTION_STATUS_FAILED} from '../constants/Transmitter'

export const requestTrans = (siteid) => {
    return {
        type: REQUEST_TRANS,
        siteid
    }
}

export const requestTransLoading = (isLoading) => {
    return {
        type: REQUEST_TRANS_LOADING,
        isLoading
    }
}

export const requestTransSuccess = (data) => {
    return {
        type: REQUEST_TRANS_SUCCESS,
        data
    }
}

export const requestTransFailed = (error) => {
    return {
        type: REQUEST_TRANS_FAILED,
        error
    }
}

export const requestTransModify = (trans) => {
    return {
        type: REQUEST_TRANS_MODIFY,
        trans
    }
}

export const requestTransModifySuccess = (trans) => {
    return {
        type: REQUEST_TRANS_MODIFY_SUCCESS,
        trans
    }
}

export const requestTransModifyFailed = (error) => {
    return {
        type: REQUEST_TRANS_MODIFY_FAILED,
        error
    }
}

export const requestReceiverModify = (receiver) => {
    return {
        type: REQUEST_RECEIVER_MODIFY,
        receiver
    }
}

export const requestReceiverModifySuccess = (receiver) => {
    return {
        type: REQUEST_RECEIVER_MODIFY_SUCCESS,
        receiver
    }
}

export const requestReceiverModifyFailed = (error) => {
    return {
        type: REQUEST_RECEIVER_MODIFY_FAILED,
        error
    }
}


export const updateConnectionStatus = (status) => {
    return {
        type: UPDATE_CONNECTION_STATUS,
        status
    }
}

export const waitConnectionStatus = (connection) => {
    return {
        type: WAIT_CONNECTION_STATUS,
        connection
    }
}

export const checkReceiverConnectionStatus = (receiver) => {
    return {
        type: CHECK_CONNECTION_STATUS,
        receiver
    }
}

export const checkReceiverConnectionStatusSuccess = () => {
    return {
        type: CHECK_CONNECTION_STATUS_SUCCESS,
    }
}

export const checkReceiverConnectionStatusFailed = () => {
    return {
        type: CHECK_CONNECTION_STATUS_FAILED,
    }
}