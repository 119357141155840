import { REQUEST_TRANS_LOADING, 
    REQUEST_TRANS_SUCCESS, 
    REQUEST_TRANS_FAILED, 
    UPDATE_CONNECTION_STATUS, 
    REQUEST_TRANS_MODIFY_SUCCESS,
    REQUEST_TRANS_MODIFY_FAILED,
    REQUEST_RECEIVER_MODIFY_SUCCESS, 
    REQUEST_RECEIVER_MODIFY_FAILED,
    CHECK_CONNECTION_STATUS_FAILED,
    CHECK_CONNECTION_STATUS_SUCCESS } from '../constants/Transmitter'

import { message } from 'antd';

const initState = {
    data: [],
    loading: false,
    err: null
}

const transmitters = (state = initState, action) => {
    switch (action.type) {
        case REQUEST_TRANS_LOADING:
            return {
                ...state,
                loading: action.isLoading,
                err: null
            }
        case REQUEST_TRANS_SUCCESS: 
            return {
                ...state,
                data: action.data,
                loading: false,
                err: null
            }
        case REQUEST_TRANS_FAILED: 
            return {
                ...state,
                data: [],
                loading: false,
                err: action.error
            }
        case REQUEST_TRANS_MODIFY_SUCCESS:
            message.success('정보저장 성공')
            return {
                ...state,
                err: null,
                data: state.data.map((trans) => trans.id === action.trans.id ? 
                        {
                            ...trans,
                            manualRecvCnt: action.trans.manualRecvCnt,
                            manualTranCnt: action.trans.manualTranCnt,
                            manualRelayCnt: action.trans.manualRelayCnt,
                            memo: action.trans.memo,
                            townName: action.trans.townName
                        }
                    :
                        trans
                    
                )
            }
        case REQUEST_TRANS_MODIFY_FAILED:
            return {
                ...state,
                err: action.error
            }
        case REQUEST_RECEIVER_MODIFY_SUCCESS: 
            message.success('정보저장 성공')
            return {
                ...state,
                err: null,
                data: state.data.map((trans) => {
                    return {
                        ...trans,
                        receivers: trans.receivers.map( (receiver) => receiver.id === action.receiver.id ? action.receiver : receiver)
                    }
                })
            }
        case REQUEST_RECEIVER_MODIFY_FAILED: 
            return {
                ...state,
                err: action.error
            }
        case UPDATE_CONNECTION_STATUS: 
            if (action.status.type === "transmitter") {
                return {
                    ...state,
                    data: state.data.map( (transmitter) => {
                        if (action.status.id === transmitter.id) { 
                            transmitter.status = action.status.status
                            transmitter.updatedTime = action.status.updatedTime
                        }
                        return transmitter
                    })
                }
            } else if (action.status.type === "receiver") {
                return {
                    ...state,
                    data: state.data.map(transmitter => {
                        transmitter.receivers.map( receiver => {
                            if (receiver.id === action.status.id) {
                                receiver.status = action.status.status
                                receiver.updatedTime = action.status.updatedTime
                            }
                            return receiver
                        })
                        return transmitter
                    })
                }
            } else {
                return state;
            }
        
        case CHECK_CONNECTION_STATUS_FAILED:
            message.error('수신 단말기 연결상태 요청 실패')
            return {
                ...state,
                err: action.error
            }

        case CHECK_CONNECTION_STATUS_SUCCESS:
            message.success('수신 단말기 연결상태 요청. 최대 15초 후에 상태가 업데이트 됩니다.')
            return state;

        default:
            return state;
    }
}

export default transmitters