export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_AADB2C_CLIENTID,
        authority: process.env.REACT_APP_AADB2C_AUTHORITY,
        knownAuthorities: [process.env.REACT_APP_AADB2C_KNOWN_AUTHORITY],
        redirectUri: process.env.REACT_APP_AADB2C_REDIRECT_URL,
        postLogoutRedirectUri: process.env.REACT_APP_AADB2C_POST_LOGOUT_REDIRECT_URL
    }
} 
export const adminLoginRequest = {
    scopes: [process.env.REACT_APP_AADB2C_ADMIN_LOGIN_SCOPE]
}
export const userLoginRequest = {
    scopes: [process.env.REACT_APP_AADB2C_USER_LOGIN_SCOPE]
}
export const loginRequest = {
    scopes: [process.env.REACT_APP_AADB2C_USER_LOGIN_SCOPE]
}
export const forgotPasswordRequest = {
    authority: process.env.REACT_APP_AADB2C_FORGET_PWD_AUTHORITY,
    redirectUri: process.env.REACT_APP_AADB2C_REDIRECT_URL,
    postLogoutRedirectUri: process.env.REACT_APP_AADB2C_POST_LOGOUT_REDIRECT_URL
}