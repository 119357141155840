import React, { useEffect, useState, lazy, Suspense } from "react";
import { useDispatch } from "react-redux";
import { requestSites } from 'redux/actions/Site'
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig'
import { useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";

import { userLoginRequest, adminLoginRequest  } from "../../configs/AADB2CConfig";
import { AUTH_TOKEN } from 'redux/constants/Auth'
import { waitRecentAlert } from 'redux/actions/Alerts'
import { waitRecentBroadcastLog } from 'redux/actions/Broadcast'
import { waitConnectionStatus } from 'redux/actions/Transmitter'
import AlertNotification from './alerts/AlertNotification'
import { setupSignalRConnection } from 'services/SignalRService';

export const AppViews = () => {
  const dispatch = useDispatch();
  const { instance, inProgress } = useMsal();
  const [atsResponse, setAtsResponse] = useState(null);

  // Get account info (id token)
  const account = instance.getActiveAccount();

  const loginSuccess = (response) => {
    localStorage.setItem(AUTH_TOKEN, response.accessToken);
    setAtsResponse(response);

    setInterval(() => {
      var authreq = account.idTokenClaims.extension_UserType === "Admin" ? {...adminLoginRequest, account: account} : {...userLoginRequest, account: account}
      instance.acquireTokenSilent(authreq).then((res) => {
        localStorage.setItem(AUTH_TOKEN, res.accessToken);
        setAtsResponse(res);
      });
    }, 3600000);

    if (account) dispatch(requestSites(account.idTokenClaims.extension_SiteId));
  };

  useEffect(() => {
    if (!atsResponse && account && inProgress === InteractionStatus.None) {
        var authReqeust = account.idTokenClaims.extension_UserType === "Admin" ? {...adminLoginRequest, account: account} : {...userLoginRequest, account: account}
        instance.acquireTokenSilent(authReqeust).then((res) => {
          loginSuccess(res)
        })
        .catch((err) => {
          console.log("silent token acquisition fails. acquiring token using popup");
          return instance.acquireTokenRedirect(authReqeust).then(tokenResponse => {
            loginSuccess(tokenResponse)
          }).catch(error => {
              console.log(error);
          });
        });

    }
    // eslint-disable-next-line
  }, [account, inProgress, instance, atsResponse, dispatch]);

  // connect signalR here... 
  useEffect(() => {
    const connection = setupSignalRConnection()
    dispatch(waitRecentAlert(connection))
    dispatch(waitConnectionStatus(connection))
    dispatch(waitRecentBroadcastLog(connection))
  }, [dispatch])

  return (
    <Suspense fallback={<Loading cover="content"/>}>
      <AlertNotification />
      <Switch>
        <Route path={`${APP_PREFIX_PATH}/dashboards`} exact component={lazy(() => import(`./dashboards`))} />
        <Route path={`${APP_PREFIX_PATH}/monitor`} exact component={lazy(() => import(`./monitor`))} />
        <Route path={`${APP_PREFIX_PATH}/monitor/:id`} exact component={lazy(() => import(`./monitor`))} />
        <Route path={`${APP_PREFIX_PATH}/monitor/t/:tid`} exact component={lazy(() => import(`./monitor`))} />
        <Route path={`${APP_PREFIX_PATH}/alerts`} exact component={lazy(() => import(`./alerts`))} />
        <Route path={`${APP_PREFIX_PATH}/devices`} exact component={lazy(() => import(`./devices`))} />
        <Route path={`${APP_PREFIX_PATH}/devices/:id`} exact component={lazy(() => import(`./devices`))} />
        <Route path={`${APP_PREFIX_PATH}/transmitters`} exact component={lazy(() => import(`./transmitters`))} />
        <Route path={`${APP_PREFIX_PATH}/transmitters/:id`} exact component={lazy(() => import(`./transmitters`))} />
        <Route path={`${APP_PREFIX_PATH}/device-detail/:id`} exact component={lazy(() => import(`./device-form`))} />
        <Route path={`${APP_PREFIX_PATH}/broadcasts`} exact component={lazy(() => import(`./broadcasts/delivery`))} />
        <Route path={`${APP_PREFIX_PATH}/broadcasts/logs`} exact component={lazy(() => import(`./broadcasts/broadcast-logs`))} />
        <Route path={`${APP_PREFIX_PATH}/broadcasts/delivery`} exact component={lazy(() => import(`./broadcasts/delivery`))} />
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/dashboards`} />
      </Switch>
    </Suspense> 
  )
}

export default React.memo(AppViews);