import { 
    REQUEST_DASHBOARD_SUCCESS, 
    REQUEST_DASHBOARD_FAILED, 
    REQUEST_DASHBOARD_LOADING,
    UPDATE_ALERT_COUNT,
    ADD_ALERT_COUNT } from '../constants/Dashboard'

const initState = {
    data: null,
    loading: false,
    err: null
}

const dashboard = (state = initState, action) => {
    switch (action.type) {
        case REQUEST_DASHBOARD_LOADING:
            return {
                ...state,
                loading: action.isLoading,
                err: null
            }
        case REQUEST_DASHBOARD_SUCCESS: 
            return {
                ...state,
                data: action.data,
                loading: false,
                err: null
            }
        case REQUEST_DASHBOARD_FAILED: 
            return {
                ...state,
                data: null,
                loading: false,
                err: action.error
            }
        case UPDATE_ALERT_COUNT: 
            return {
                ...state,
                data: { ...state.data, unhandledAlertCount: state.data !== null ? state.data.unhandledAlertCount + action.num : 1 }
            }
        case ADD_ALERT_COUNT: 
            return {
                ...state,
                data: { 
                        ...state.data, 
                        
                        todayAlertCount: state.data !== null ? state.data.todayAlertCount + 1 : 1,
                        unhandledAlertCount: state.data !== null ? state.data.unhandledAlertCount + 1 : 1 
                    }
        }
        default:
            return state;
    }
}

export default dashboard