import { REQUEST_SITES_SUCCESS, REQUEST_SITES_FAILED, REQUEST_SITES_LOADING, SITE_MAIN_LOGO, SITE_SUB_LOGO } from '../constants/Site'

const initState = {
    data: null,
    loading: false,
    err: null
}

const site = (state = initState, action) => {
    switch (action.type) {
        case REQUEST_SITES_LOADING:
            return {
                ...state,
                loading: action.isLoading,
                err: null
            }
        case REQUEST_SITES_SUCCESS: 
            if (action.data.mainLogoFileUrl) localStorage.setItem(SITE_MAIN_LOGO, action.data.mainLogoFileUrl)
            if (action.data.subLogoFileUrl) localStorage.setItem(SITE_SUB_LOGO, action.data.subLogoFileUrl)
            return {
                ...state,
                data: action.data,
                loading: false,
                err: null
            }
        case REQUEST_SITES_FAILED: 
            return {
                ...state,
                data: [],
                loading: false,
                err: action.error
            }
        default:
            return state;
    }
}

export default site