import {
    REQUEST_BROADCASTS_GROUP, REQUEST_BROADCASTS_GROUP_LOADING, REQUEST_BROADCASTS_GROUP_SUCCESS, REQUEST_BROADCASTS_GROUP_FAILED,
    UPDATE_BROADCASTS_GROUP, UPDATE_BROADCASTS_GROUP_SUCCESS, UPDATE_BROADCASTS_GROUP_FAILED,
    ADD_BROADCASTS_GROUP, ADD_BROADCASTS_GROUP_SUCCESS, ADD_BROADCASTS_GROUP_FAILED,
    DELETE_BROADCASTS_GROUP, DELETE_BROADCASTS_GROUP_SUCCESS, DELETE_BROADCASTS_GROUP_FAILED
} from '../constants/BroadcastGroup'

export const requestBroadcastsGroup = (siteid) => {
    return {
        type: REQUEST_BROADCASTS_GROUP,
        siteid
    }
}

export const requestBroadcastsGroupLoading = (isLoading) => {
    return {
        type: REQUEST_BROADCASTS_GROUP_LOADING,
        isLoading
    }
}

export const requestBroadcastsGroupSuccess = (data) => {
    return {
        type: REQUEST_BROADCASTS_GROUP_SUCCESS,
        data
    }
}

export const requestBroadcastsGroupFailed = (error) => {
    return {
        type: REQUEST_BROADCASTS_GROUP_FAILED,
        error
    }
}

export const updateBroadcastsGroup = (siteid, id, info) => {
    console.log(siteid, info)
    return {
        type: UPDATE_BROADCASTS_GROUP,
        siteid,
        id,
        info   
    }
}

export const updateBroadcastsGroupSuccess = (data) => {
    return {
        type: UPDATE_BROADCASTS_GROUP_SUCCESS,
        data
    }
}

export const updateBroadcastsGroupFailed = (error) => {
    return {
        type: UPDATE_BROADCASTS_GROUP_FAILED,
        error
    }
}

export const addBroadcastsGroup = (siteid, name, trans) => {
    return {
        type: ADD_BROADCASTS_GROUP,
        siteid,
        name,
        trans
    }
}

export const addBroadcastsGroupSuccess = (data) => {
    return {
        type: ADD_BROADCASTS_GROUP_SUCCESS,
        data
    }
}

export const addBroadcastsGroupFailed = (error) => {
    return {
        type: ADD_BROADCASTS_GROUP_FAILED,
        error
    }
}

export const deleteBroadcastsGroup = (siteid, id) => {
    return {
        type: DELETE_BROADCASTS_GROUP,
        siteid,
        id
    }
}

export const deleteBroadcastsGroupSuccess = (id) => {
    return {
        type: DELETE_BROADCASTS_GROUP_SUCCESS,
        id
    }
}

export const deleteBroadcastsGroupFailed = (error) => {
    return {
        type: DELETE_BROADCASTS_GROUP_FAILED,
        error
    }
}