import { all, takeEvery, put, fork, call } from 'redux-saga/effects';

import { REQUEST_SITES } from '../constants/Site'
import { requestSitesLoading, requestSitesSuccess, requestSitesFailed} from '../actions/Site'
import TowncastService from '../../services/TowncastService'

export function* requestSite() {
    yield takeEvery(REQUEST_SITES, function* (action) {
        try {
            yield put(requestSitesLoading(true))
            const response = yield call(TowncastService.getSite, action.siteid)
            yield put(requestSitesSuccess(response.data))
        } catch (err) {
            yield put(requestSitesFailed(err))
        }
    })
}

export default function* rootSaga() {
    yield all([
        fork(requestSite)
    ])
}