export const REQUEST_BROADCASTS_GROUP = 'REQUEST_BROADCASTS_GROUP'
export const REQUEST_BROADCASTS_GROUP_LOADING = 'REQUEST_BROADCASTS_GROUP_LOADING'
export const REQUEST_BROADCASTS_GROUP_SUCCESS = 'REQUEST_BROADCASTS_GROUP_SUCCESS'
export const REQUEST_BROADCASTS_GROUP_FAILED = 'REQUEST_BROADCASTS_GROUP_FAILED'

export const UPDATE_BROADCASTS_GROUP = 'UPDATE_BROADCASTS_GROUP'
export const UPDATE_BROADCASTS_GROUP_SUCCESS = 'UPDATE_BROADCASTS_GROUP_SUCCESS'
export const UPDATE_BROADCASTS_GROUP_FAILED = 'UPDATE_BROADCASTS_GROUP_FAILED'

export const ADD_BROADCASTS_GROUP = 'ADD_BROADCASTS_GROUP'
export const ADD_BROADCASTS_GROUP_SUCCESS = 'ADD_BROADCASTS_GROUP_SUCCESS'
export const ADD_BROADCASTS_GROUP_FAILED = 'ADD_BROADCASTS_GROUP_FAILED'

export const DELETE_BROADCASTS_GROUP = 'DELETE_BROADCASTS_GROUP'
export const DELETE_BROADCASTS_GROUP_SUCCESS = 'DELETE_BROADCASTS_GROUP_SUCCESS'
export const DELETE_BROADCASTS_GROUP_FAILED = 'DELETE_BROADCASTS_GROUP_FAILED'